const quizFlow =  [
  {
    categoryId: "EXPRESSION",
    currentGroupIndex: 0,
    questions: ["E1", "E2", "E3", "E4","E5", "E6", "E7", "E8","E9", "E10", "E11", "E12", "E13", "E14", "E15", "E20", "E19", "E18", "E17", "E16"],
    hasMultipleAnswers: false,
  },
  {
    categoryId: "SPEECH",
    currentGroupIndex: 1,
    questions: ["S2"],
    hasMultipleAnswers: true,
  },
  {
    categoryId: "COMPREHENSION",
    currentGroupIndex: 2,
    questions: ["R6", "R7", "R8","R9", "R10", "R11", "R12","R13", "R14", "R15", "R5", "R4", "R3", "R2", "R1"],
    hasMultipleAnswers: false,
  },
  {
    categoryId: "READINESS",
    currentGroupIndex: 3,
    questions: ["B1", "B2", "B3", "B4", "B5"],
    hasMultipleAnswers: false,
  },
];

export default quizFlow;