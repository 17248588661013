import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { updateMe, me } from "../api/users";

import { Spinner } from "react-bootstrap";

const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  align-self: left;
`;

const StyledFormControl = styled(Form.Control)`
  font-size: 18px;
`;

const WidgetSection = styled.div`
  width: 100%;
`;

const ChildName = ({ nameSubmitted }) => {
  const [nameError, setNameError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [childName, setChildName] = useState('');

  useEffect(async ()=>{
    let mydetails = await me();
    if(mydetails) {
      const {childName} = mydetails;
      setChildName(childName);
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateMe({childName});
      setLoading(false);
      nameSubmitted();
    } catch (err) {
      setNameError(err.toString());
    }
  };
  
  return (
    <WidgetSection>
      <Container>
        <Form.Group className="mb-4">
          <StyledFormLabel>
            <b>What is name of your child ?</b>
          </StyledFormLabel>
          <StyledFormControl
            type="text"
            value={childName}
            onChange={(e)=>{setChildName(e.target.value)}}
            name="childName"
          />
          {nameError && (
            <Form.Text className="text-danger">{nameError}</Form.Text>
          )}
        </Form.Group>
        <div className="text-right mt-5">
          <Button
            variant="warning"
            size="lg"
            onClick={()=>{handleSubmit()}}
            type="submit"
            className="px-4"
            disabled={!childName || isLoading}
          >
            Next{" "}
            {isLoading && (
              <Spinner
                animation="border"
                as="span"
                animation="border"
                size="sm"
              />
            )}
          </Button>
        </div>
      </Container>
    </WidgetSection>
  );
};

export default ChildName;
