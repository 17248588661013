import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import quizFlow from "../config/newflow";
import Loading from "../components/Loading";
import { getUserQuiz, takeQuiz } from "../api/quiz";
import { updateMe } from "../api/users";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const QuizLayoutWrapper = styled.div`
  width: 100%;
`;

const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  margin-top: 30px;
  margin-bottom: 16px;
`;

const WidgetSection = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`;

const AnswerBlock = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  color: #000;
  border: 1px solid #cacaca;
  cursor: pointer;
`;

const SelectedAnswerBlock = styled.div`
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  background-color: #FF6667;
  align-items: center;
  color: #fff;
  cursor: pointer;
`;

const AnswerLabel = styled.p`
  margin-bottom: 0px;
`;

const PrimaryButton = styled.button`
  background-color: #271344 !important;
  border: none !important;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  padding: 0 30px;
  height: 50px;
  color: #fff;
`;


const Quiz = ({ data, incrementPagination }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [syncData, setSyncData] = useState(false);
  const [skippedQues, setSkippedQues] = useState([]);
  const [cb, setCb] = useState(1);
  const [questionFlow, setQuestionFlow] = useState({
    currentGroup: quizFlow[0],
    currentQuestionId: quizFlow[0]['questions'][0]
  })
  const [quesAnswered, setQuesAnswered] = useState({
    'EXPRESSION': [],
    'SPEECH': [],
    'COMPREHENSION': [],
    'READINESS': [],
    "ALL": []
  });

  useEffect(() => {
    navigateToNextDefault();
  }, [cb]);

  useEffect(async () => {
    const quiz = await getUserQuiz();
    if (quiz) {
      const {
        userAnswers: apiUserAnswers,
        questionIndex: savedQuestionIndex,
        currentGroupIndex: savedCurrentGroupIndex,
        questionsAnswered
      } = quiz;
      let newUserAnswers = {};
      apiUserAnswers.forEach(userAnswer => {
        newUserAnswers[userAnswer.questionId] = {
          answers: userAnswer.answers,
          possibleOptions: userAnswer.possibleOptions,
          question: userAnswer.question || "",
          result: userAnswer.result
        }
      });
      let obj = {
        'EXPRESSION': [],
        'SPEECH': [],
        'COMPREHENSION': [],
        'READINESS': [],
        "ALL": questionsAnswered
      };
      questionsAnswered.forEach(questionCode => {
        if (questionCode.charAt(0) == 'E') {
          obj['EXPRESSION'] = [...obj['EXPRESSION'], questionCode];
        } else if (questionCode.charAt(0) == 'B') {
          obj['READINESS'] = [...obj['READINESS'], questionCode];
        } else if (questionCode.charAt(0) == 'R') {
          obj['COMPREHENSION'] = [...obj['COMPREHENSION'], questionCode];
        } else {
          obj['SPEECH'] = [...obj['SPEECH'], questionCode];
        }
      })
      setUserAnswers(newUserAnswers);
      setQuesAnswered(obj);
      setQuestionFlow({
        currentGroup: quizFlow[savedCurrentGroupIndex],
        currentQuestionId: savedQuestionIndex
      })
      setSyncData(true);
    }
  }, []);

  useEffect(() => {
    if (syncData) {
      nextQuestion(false);
    }
  }, [syncData])

  const getContentfulQuestion = questionId => {
    return data.questionnaire.nodes.find(
      question => question.contentfulid === questionId
    );
  };

  const isNextButtonDisabled = () => {
    if (userAnswers && userAnswers[questionFlow.currentQuestionId]) {
      return false
    } else {
      return true;
    }
  }

  const isBackButtonDisabled = () => {
    if (questionFlow['currentQuestionId'] === quizFlow[0]['questions'][0]) {
      return true
    }
    return false;
  }

  const selectAnswer = ans => {
    let questionId = questionFlow.currentQuestionId;
    let categoryId = questionFlow.currentGroup.categoryId;
    const { correctAnswers, answers, question: { question } } = getContentfulQuestion(questionId);
    console.log("Correct Answers",correctAnswers);
    console.log("Answer",ans);
    setUserAnswers({
      ...userAnswers,
      [questionId]: ans
        ? {
          result: correctAnswers.some(
            correctAnswer => correctAnswer.trim() === ans.trim()
          ),
          answers: [ans],
          question: question,
          possibleOptions: [...answers]
        }
        : undefined,
    });

    if (!quesAnswered['ALL'].includes(questionId)) {
      setQuesAnswered({
        ...quesAnswered,
        [categoryId]: [...quesAnswered[categoryId], questionId],
        'ALL': [...quesAnswered['ALL'], questionId]
      });
    }
  }

  const selectMultiAnswer = ans => {
    let questionId = questionFlow.currentQuestionId;
    let categoryId = questionFlow.currentGroup.categoryId;
    const { correctAnswers, answers, question: { question } } = getContentfulQuestion(questionId);
    const currentUserAnswers = userAnswers[questionId]
      ? userAnswers[questionId].answers
      : [];

    let newUserAnswers;
    if (currentUserAnswers.find(answer => answer === ans)) {
      newUserAnswers = currentUserAnswers.filter(answer => answer !== ans);
    } else {
      newUserAnswers = [...currentUserAnswers, ans];
    }

    setUserAnswers({
      ...userAnswers,
      [questionId]: {
        result: correctAnswers.some(
          correctAnswer =>
            correctAnswer === newUserAnswers.includes(correctAnswer)
        ),
        answers: newUserAnswers,
        question: question,
        possibleOptions: [...answers]
      },
    });
    if (!quesAnswered['ALL'].includes(questionId)) {
      setQuesAnswered({
        ...quesAnswered,
        [categoryId]: [...quesAnswered[categoryId], questionId],
        'ALL': [...quesAnswered['ALL'], questionId]
      });
    }
  }

  const getCategoryIdFromQueKey = (questionCode) => {
    if (questionCode.charAt(0) == 'E') {
      return 0;
    } else if (questionCode.charAt(0) == 'B') {
      return 3;
    } else if (questionCode.charAt(0) == 'R') {
      return 2;
    } else {
      return 1;
    }
  }

  const previousQuestion = () => {
    let questions = [...quesAnswered['ALL']];
    let currentQuestionId = questionFlow['currentQuestionId'];
    if (questions.length) {
      let index = questions.findIndex(que => que === currentQuestionId);
      if (index < 0) {
        index = questions.length;
      }
      let queId = questions[index - 1];
      let categoryGroup = getCategoryIdFromQueKey(queId);
      if (categoryGroup === 0 && currentQuestionId === 'E1') {
        //console.log("Very First Page !!!")
      } else {
        setQuestionFlow({
          currentGroup: quizFlow[categoryGroup],
          currentQuestionId: queId,
        })
      }
    }
  }

  const saveQuiz = async (data = null) => {
    let content = data || userAnswers;
    let currentQuestionId = questionFlow.currentQuestionId;
    try {
      setLoading(true);
      await takeQuiz({
        userAnswers: Object.keys(content).map(key => ({
          questionId: key,
          ...content[key],
        })),
        questionIndex: currentQuestionId,
        questionsAnswered: quesAnswered['ALL'],
        currentGroupIndex: questionFlow.currentGroup.currentGroupIndex
      });
      setLoading(false);
      setUserAnswers(content);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    } catch (err) {
      setError(err.toString());
      setLoading(false);
    }
  }

  const gotoBasicQuestions = (queType) => {
    if (queType === "EXPRESSION") {
      let basicQuesIndex = ['E20', 'E19', 'E18', 'E17', 'E16'];
      //set question to E20 if not in basic ones ...
      if ((
        !userAnswers['E20'] ||
        !userAnswers['E19'] ||
        !userAnswers['E18'] ||
        !userAnswers['E17'] ||
        !userAnswers['E16']
      ) && !basicQuesIndex.includes(questionFlow.currentQuestionId)
      ) {
        setQuestionFlow({
          ...questionFlow,
          currentQuestionId: 'E20'
        });
      }
    }

    if (queType === "COMPREHENSION") {
      let basicQuesIndex = ['R5', 'R4', 'R3', 'R2', 'R1'];
      if ((
        !userAnswers['R1'] ||
        !userAnswers['R2'] ||
        !userAnswers['R3'] ||
        !userAnswers['R4'] ||
        !userAnswers['R5']
      ) && !basicQuesIndex.includes(questionFlow.currentQuestionId)
      ) {
        setQuestionFlow({
          ...questionFlow,
          currentQuestionId: 'R5'
        });
      }
    }
  }

  const traverseExpressionConditionsCheck = () => {
    let currentFlow = questionFlow.currentGroup;
    if (
      userAnswers['E6'] &&
      userAnswers['E6']['result'] &&
      userAnswers['E7'] &&
      userAnswers['E7']['result'] &&
      userAnswers['E8'] &&
      userAnswers['E8']['result']
    ) {
      let categoryGroupIndex = currentFlow.currentGroupIndex + 1;
      setQuestionFlow({
        currentGroup: quizFlow[categoryGroupIndex],
        currentQuestionId: quizFlow[categoryGroupIndex]['questions'][0]
      })
    } else {
      let categoryGroupIndex = currentFlow.currentGroupIndex + 2;
      setQuestionFlow({
        currentGroup: quizFlow[categoryGroupIndex],
        currentQuestionId: quizFlow[categoryGroupIndex]['questions'][0]
      })
    }
  }

  const handleNoCases = () => {
    let currentFlow = questionFlow.currentGroup;
    let questionId = questionFlow.currentQuestionId;
    let index = currentFlow['questions'].findIndex(item => item === questionId);
    const { answers } = getContentfulQuestion(questionId);

    if (currentFlow['categoryId'] === 'EXPRESSION') {
      let obj = {};
      let skipQue = [...skippedQues];
      currentFlow['questions'].slice(index + 1, -5).forEach((item) => {
        const data = getContentfulQuestion(item);
        let question = data?.question?.question || '';
        skipQue.push(item);
        obj[item] = {
          result: false,
          answers: [answers[0]],
          question: question,
          possibleOptions: [...answers]
        }
      });
      saveQuiz({
        ...userAnswers,
        ...obj
      });
      setSkippedQues(skipQue);
      gotoBasicQuestions('EXPRESSION');
      setCb(cb + 1);
    }

    if (currentFlow['categoryId'] === 'COMPREHENSION') {
      let obj = {};
      let skipQue = [...skippedQues];
      currentFlow['questions'].slice(index + 1, -5).forEach((item) => {
        const data = getContentfulQuestion(item);
        let question = data?.question?.question || '';
        skipQue.push(item);
        obj[item] = {
          result: false,
          answers: [answers[0]],
          question: question,
          possibleOptions: [...answers]
        }
      });
      saveQuiz({
        ...userAnswers,
        ...obj
      });
      setSkippedQues(skipQue);
      gotoBasicQuestions('COMPREHENSION');
      setCb(cb + 1);
    }

  }

  const handleYesCases = () => {
    let currentFlow = questionFlow.currentGroup;
    let questionId = questionFlow.currentQuestionId;
    const { answers } = getContentfulQuestion(questionId);
    if (currentFlow['categoryId'] === 'EXPRESSION') {
      // Saving Last 5 answers as true locally...
      let obj = {};
      let skipQue = [...skippedQues];
      currentFlow['questions'].slice(-5).forEach((item) => {
        const data = getContentfulQuestion(item);
        let question = data?.question?.question || '';
        skipQue.push(item);
        obj[item] = {
          result: true,
          answers: [answers[answers.length - 1]],
          question: question,
          possibleOptions: [...answers]
        }
      });
      // Saving Last 5 answers as true in DB...
      saveQuiz({
        ...userAnswers,
        ...obj
      });
      setSkippedQues(skipQue);
      navigateToNextDefault();
    }

    if (currentFlow['categoryId'] === 'COMPREHENSION') {
      let categoryGroupIndex = currentFlow.currentGroupIndex + 1;
      let obj = {};
      let skipQue = [...skippedQues];
      currentFlow['questions'].slice(-5).forEach((item) => {
        const data = getContentfulQuestion(item);
        let question = data?.question?.question || '';
        skipQue.push(item);
        obj[item] = {
          result: true,
          answers: [answers[answers.length - 1]],
          question: question,
          possibleOptions: [...answers]
        }
      });
      setSkippedQues(skipQue);
      saveQuiz({
        ...userAnswers,
        ...obj
      });
      navigateToNextDefault();
    }
  }

  const navigateToNextDefault = async () => {
    let currentFlow = questionFlow.currentGroup;
    let questions = currentFlow.questions;
    let currentQuestionId = questionFlow.currentQuestionId;
    let totalQues = questions.length;
    let index = questions.findIndex(que => que === currentQuestionId);
    if (currentQuestionId === 'S2' && userAnswers[currentQuestionId] === undefined) {
      return;
    }
    let nextQueId = currentFlow['questions'][index + 1] || undefined;
    if (skippedQues.includes(nextQueId)) {
      setQuestionFlow({
        currentGroup: currentFlow,
        currentQuestionId: currentFlow['questions'][index + 1]
      })
      incrementPagination(userAnswers);
      setCb(cb + 1); //triggering callback hook
      return;
    }
    if (index > -1 && index + 2 <= totalQues) { // Has more questions in array...
      if (userAnswers[currentQuestionId] !== undefined) {
        let categoryGroup = currentFlow.currentGroupIndex;
        setQuestionFlow({
          currentGroup: currentFlow,
          currentQuestionId: currentFlow['questions'][index + 1]
        })
      }
    } else { // Last que, needs to jump to next category...
      if (currentFlow['categoryId'] === 'EXPRESSION') {
        traverseExpressionConditionsCheck();
      } else {
        let categoryGroup = currentFlow.currentGroupIndex + 1;
        if (categoryGroup >= quizFlow.length) {
          await updateMe({ isFinishedTakingQuiz: true });
          // window.location.href = '/findings';
          window.location.href = '/toddler-talk-assessment-findings'
        } else {
          setQuestionFlow({
            currentGroup: quizFlow[categoryGroup],
            currentQuestionId: quizFlow[categoryGroup]['questions'][0]
          })
        }
      }
    }
  }

  const cons5YesCounter = () => {
    let count = 0;
    let questions = quesAnswered[questionFlow['currentGroup']['categoryId']];
    if (questions.length >= 5) {
      let temp = [...questions.slice(-5)];
      temp.forEach((current) => {
        if (userAnswers[current].result) {
          count += 1;
        } else {
          count = 0;
        }
      })
      if (count === 5) return true;
    }
    return false;
  }

  const cons3NoCounter = () => {
    let count = 0;
    let questions = quesAnswered[questionFlow['currentGroup']['categoryId']];
    if (questions.length >= 3) {
      let temp = [...questions.slice(-3)];
      temp.forEach((current) => {
        if (!userAnswers[current].result) {
          count += 1;
        } else {
          count = 0;
        }
      })
      if (count === 3) return true;
    }
    return false;
  }

  const nextQuestion = async (needToSave = true) => {
    let currentFlow = questionFlow.currentGroup;
    let answeredQues = quesAnswered[currentFlow['categoryId']];
    let currentQuestionId = questionFlow.currentQuestionId;
    let basicQuestions = ['E20', 'E19', 'E18', 'E17', 'E16', 'R1', 'R2', 'R3', 'R4', 'R5'];
    if (needToSave) {
      saveQuiz();
    }
    incrementPagination(userAnswers);
    if ((currentFlow['categoryId'] === "EXPRESSION") || (currentFlow['categoryId'] === "COMPREHENSION")) {
      if (answeredQues.length >= 3) {
        if (!basicQuestions.includes(answeredQues[answeredQues.length - 1])) {
          if (cons3NoCounter()) {
            handleNoCases();
          } else {
            if (cons5YesCounter()) {
              handleYesCases();
            } else {
              navigateToNextDefault();
            }
          }
        } else {
          navigateToNextDefault();
        }
      } else {
        navigateToNextDefault();
      }
    } else {
      navigateToNextDefault();
    }
  }

  const renderQuizQuestion = () => {
    let questionId = questionFlow.currentQuestionId;
    let hasMultipleAnswers = questionFlow.currentGroup.hasMultipleAnswers;
    const data = getContentfulQuestion(questionId);
    const { contentfulid, answers, question: { question } } = data;
    return (
      <>
        {/* <StyledFormLabel>{contentfulid}</StyledFormLabel> */}
        <StyledFormLabel>{question}</StyledFormLabel>
        {
          answers.map((answer, index) => {
            if (answers.length && userAnswers[questionId] && userAnswers[questionId]['answers'].includes(answer)) {
              return (
                <SelectedAnswerBlock
                  key={index.toString()}
                  onClick={() => { hasMultipleAnswers ? selectMultiAnswer(answer) : selectAnswer(answer) }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    style={{ marginRight: '8px', color: '#fff' }}
                  />
                  <AnswerLabel>{answer}</AnswerLabel>
                </SelectedAnswerBlock>
              )
            } else {
              return (
                <AnswerBlock
                  key={index.toString()}
                  onClick={() => { hasMultipleAnswers ? selectMultiAnswer(answer) : selectAnswer(answer) }}>
                  <AnswerLabel>{answer}</AnswerLabel>
                </AnswerBlock>
              )
            }
          })
        }
      </>
    )
  }

  return (
    <QuizLayoutWrapper>
      <WidgetSection>
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              {renderQuizQuestion()}
              <div className='d-flex justify-content-between align-items-center mt-5'>
                <PrimaryButton
                  onClick={previousQuestion}
                  style={isBackButtonDisabled() ? { opacity: 0.5 } : {}}
                  disabled={isBackButtonDisabled()}
                >
                  Back
                </PrimaryButton>

                <PrimaryButton
                  disabled={isNextButtonDisabled()}
                  style={isNextButtonDisabled() ? { opacity: 0.5 } : {}}
                  onClick={nextQuestion}
                >
                  Next
                </PrimaryButton>
              </div>
            </Col>
          </Row>
        </Container>
      </WidgetSection>
    </QuizLayoutWrapper>
  )
}

export default Quiz;
