import React, { useState, useRef } from "react";
import { Form, Button, Container, Checkbox } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { validateEmail, registerUser } from "../api/users";

import { Spinner } from "react-bootstrap";

const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
  align-self: left;
`;

const StyledFormControl = styled(Form.Control)`
  font-size: 18px;
`;

const WidgetSection = styled.div`
  width: 100%;
`;

const GeneralInfo = ({ registrationDone }) => {
  const { register, handleSubmit, formState, watch, errors } = useForm({
    mode: "onChange",
  });
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [agreeStatus, setAgreeStatus] = useState(false);
  const password = useRef({});
  password.current = watch("password", "");

  const handlePreSubmit = async info => {
    setLoading(true);
    try {
      const isValidEmail = await validateEmail({ email: info.email });
      setLoading(false);
      if (!isValidEmail) {
        setEmailError("Email is already registered.");
        setLoading(false);
      } else {
        const data = await registerUser(info);
        if (data && data.token) {
          localStorage.setItem("token", data.token);
          registrationDone();
          setLoading(false);
        }
      }
    } catch (err) {
      setEmailError(err.toString());
      setLoading(false);
    }
  };

  const changeEmailStatus = () => {
    if (emailError !== null) {
      setEmailError(null)
    }
  }

  return (
    <WidgetSection>
      <Container>
        <Form onSubmit={handleSubmit(handlePreSubmit)}>
          <Form.Group className="mb-4">
            <StyledFormLabel>
              <b>Parent Name</b>
            </StyledFormLabel>
            <StyledFormControl
              type="text"
              ref={register({ required: true })}
              name="name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <StyledFormLabel>
              <b>Child Name</b>
            </StyledFormLabel>
            <StyledFormControl
              type="text"
              ref={register({ required: true })}
              name="childName"
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <StyledFormLabel>
              <b>Child Gender</b>
            </StyledFormLabel>
            <StyledFormControl
              ref={register({ required: true })}
              as="select"
              name='childGender'
              className="form-control"
            >
              <option value="">Select Gender</option>
              <option value="Boy">Male</option>
              <option value="Girl">Female</option>
            </StyledFormControl>
          </Form.Group>

          <Form.Group className="mb-4">
            <StyledFormLabel>
              <b>Child Birthday</b>
            </StyledFormLabel>
            <StyledFormControl
              ref={register({ required: true })}
              type="date"
              name="childBirthday"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <StyledFormLabel>
              <b>Email Address</b>?
            </StyledFormLabel>
            <StyledFormControl
              type="email"
              onChange={changeEmailStatus}
              ref={register({ required: true })}
              name="email"
            />
            {emailError && (
              <Form.Text className="text-danger">{emailError}</Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <StyledFormLabel>
              <b>Create Password</b>?
            </StyledFormLabel>
            <StyledFormControl
              type="password"
              ref={register({ required: true })}
              name="password"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <StyledFormLabel>
              <b>Confirm Password</b>
            </StyledFormLabel>
            <StyledFormControl
              type="password"
              ref={register({
                required: true,
                validate: value =>
                  value === password.current ||
                  "The passwords do not match",
              })}
              name="confirm_password"
            />
            {errors.confirm_password && (
              <Form.Text className="text-danger">
                {errors.confirm_password.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mt-4 text-left" controlId="formBasicCheckbox">
            <label>
              <input
                checked={agreeStatus}
                onChange={() => { setAgreeStatus(!agreeStatus) }}
                type="checkbox" />
              <span
                style={{ fontWeight: '600', color: '#000' }}
                className='ml-2' >
                I agree to
                <a
                  style={{ color: '#000', textDecorationLine: 'underline' }}
                  // href='/terms'
                  href="https://toddlertalk.com/terms-and-conditions"
                  target="_blank">
                  {" "}terms and conditions
                </a> &
                <a
                  style={{ color: '#000', textDecorationLine: 'underline' }}
                  // href='/privacy'
                  href="https://toddlertalk.com/privacy-policy"
                  target="_blank">
                  {" "}privacy policy
                </a>
              </span>
            </label>
          </Form.Group>



          <div className="text-right mt-5">
            <Button
              variant="warning"
              size="lg"
              type="submit"
              className="px-4"
              disabled={!formState.isValid || isLoading || !agreeStatus}
            >
              Begin Quiz{" "}
              {isLoading && (
                <Spinner
                  animation="border"
                  as="span"
                  size="sm"
                />
              )}
            </Button>
          </div>
        </Form>
      </Container>
    </WidgetSection>
  );
};

export default GeneralInfo;
