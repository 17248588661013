import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import styled from "styled-components";
import "../components/layout.scss";
import { getUserQuiz } from "../api/quiz";
import { me } from "../api/users";
import Loading from "../components/Loading";
import Layout from "../components/layout";
import quizFlow from "../config/quizFlow";
import Auth from "../components/Auth";

import GeneralInfo from "../components/GeneralInfo";
import ChildName from "../components/ChildName";
import QuizComponent from "../components/quizComponent";

const WidgetSection = styled.div`
width: 80%;
margin: 0px auto;
justify-content: center;
align-items: center;
`;

export const query = graphql`
  query {
    questionnaire: allContentfulQuestionnaire {
      nodes {
        contentfulid
        question {
          question
        }
        answers
        correctAnswers
      }
    }
    categories: allContentfulQuestionnaireCategories {
      nodes {
        categoryId
        title
        video {
          original_url
        }
        videoPlaceholderImage {
          fluid {
            src
          }
        }
      }
    }
    sidebarImg: file(relativePath: { eq: "sidebar-bottom-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrationHeader : contentfulRegistrationHeader {
      title,
      subTitle {
        subTitle
      }
    }
  }
`;


function QuizFlow({ data }) {
  const [activeScreen, setActiveScreen] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [programStatusBar, setProgramStatusBar] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    activeNodes: 1,
    inactiveNodes: 41,
    totalNodes: 42
  })

  const {
    questionnaire: { nodes: questionnaire },
    categories: { nodes: categories },
    registrationHeader: {
      title,
      subTitle: {
        subTitle
      }
    },
  } = data;

  useEffect(async () => {
    setLoading(true);
    let email = null;
    let childName = null;
    let userinfo = null;
    if (localStorage.getItem('token')) {
      userinfo = await me();
      setUserInfo(userinfo);
      if (userinfo) {
        email = userinfo.email || '';
        childName = userinfo.childName || '';
        if (userinfo.isFinishedTakingQuiz) {
          window.location.href = 'findings';
        }
      }
      const quiz = await getUserQuiz();
      if (quiz && quiz['_id']) {
        const { userAnswers } = quiz;
        let newUserAnswers = {};
        userAnswers.forEach(answer => {
          newUserAnswers[answer.questionId] = {
            answers: answer.answers,
            result: answer.result,
          };
        });
        setUserAnswers(newUserAnswers);
      }
    }
    if (!email) {
      setActiveScreen('screen1');
    } else {
      setActiveScreen('screen3');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    createBarCapsules();
  }, [userInfo, userAnswers]);


  function createBarCapsules() {
    let length = paginationInfo.totalNodes;
    let activeStatusBars = paginationInfo.activeNodes;
    let inactiveStatusBars = paginationInfo.inactiveNodes;
    let tempStatusBar = [];
    if (userInfo && userInfo.id) {
      if (userInfo.email) {
        inactiveStatusBars = inactiveStatusBars - 2;
        activeStatusBars = activeStatusBars + 2;
      }
    }

    questionnaire.forEach(question => {
      let questionID = question.contentfulid;
      if (userAnswers) {
        if (userAnswers[questionID]) {
          if (questionID === 'S2') {
            length += 1;
            inactiveStatusBars += 1;
          }
          inactiveStatusBars = inactiveStatusBars - 1
          activeStatusBars = activeStatusBars + 1;
        }
      }
    })
    for (let i = 1; i <= activeStatusBars; i++) {
      tempStatusBar.push(<div style={{ width: `calc(100vw / ${length})` }} className='progress-bar-capsule-active'></div>)
    }
    for (let i = 1; i <= inactiveStatusBars; i++) {
      tempStatusBar.push(<div style={{ width: `calc(100vw / ${length})` }} className='progress-bar-capsule'></div>)
    }
    setProgramStatusBar(tempStatusBar);
  }

  const incrementPagination = (userAnswers, length = 0) => {
    if (length) {
      setPaginationInfo({
        activeNodes: paginationInfo.activeNodes,
        inactiveNodes: length,
        totalNodes: length
      })
    }
    setUserAnswers({ ...userAnswers });
  }

  const registrationDone = async () => {
    const userinfo = await me();
    setUserInfo(userinfo);
    // setActiveScreen('screen2');
    setActiveScreen('screen3');
  }

  const nameSubmitted = async () => {
    const userinfo = await me();
    setUserInfo(userinfo);
    window.location.href = '/quiz'
  }

  const getComponent = () => {
    if (activeScreen === 'screen1') {
      return (
        <GeneralInfo
          data={data}
          registrationDone={registrationDone} />
      )
    } else if (activeScreen === 'screen2') {
      return (
        <ChildName
          data={data}
          nameSubmitted={nameSubmitted} />
      )
    } else {
      return (
        <QuizComponent
          data={data}
          incrementPagination={(userAnswers, length) => { incrementPagination(userAnswers, length) }} />
      )
    }
  }

  if (loading || !activeScreen) return <Loading />;

  return (

    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width={100}
                                                    height={100}
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <WidgetSection>
                                          {/* <p className='mb-0 mt-2 text-left'>{activeStatusBars} / {length}</p> */}
                                          <div className='progress-bar mt-3'>
                                            {programStatusBar}
                                          </div>
                                        </WidgetSection>
                                      </div>
                                    </div>
                                  </div>
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-74d00c8 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="74d00c8"
                                    data-element_type="section"
                                  >
                                    <Container>
                                      <Row className="justify-content-center">
                                        <Col md="8">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            {(activeScreen === 'screen1') && <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                              <h1 className="custom-heading"><span>{title}</span></h1>
                                              <div className="custom-heading-description mb-4" style={{ color: 'black' }}>
                                                {subTitle}
                                              </div>
                                            </div>}
                                            <div className="elementor-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                {getComponent()}
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Container>

                                  </section>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      >
                                        {/* space */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >

  )
}

export default QuizFlow;
